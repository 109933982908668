import { ReactNode } from "react";
import useAppBarHeight from "../../hooks/useAppBarHeight";

interface MainLayoutProps {
  children?: ReactNode;
}

export const MainContent = ({ children }: MainLayoutProps) => {
  const mainAppBarHeight = useAppBarHeight();
  return (
    <div
      style={{
        // border: "1px solid blue",
        height: `calc(100vh - ${mainAppBarHeight}px)`,
        overflowY: "auto",
        width: "100vw",
        overflowX: "auto",
        backgroundColor: "#EEEEEE",
      }}
    >
      {children}
    </div>
  );
};
