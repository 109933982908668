import { Auth } from "@aws-amplify/auth";
import { useState, useEffect } from "react";
import { CognitoUserExt } from "../components/UI/CognitoTypes";
// import { useQuery } from "@tanstack/react-query";

// https://github.com/aws-amplify/amplify-js/issues/4927

export function useCurrentUser() {
  // Returns the currently signed in user
  const [user, setUser] = useState<CognitoUserExt | undefined>(undefined);

  useEffect(() => {
    async function fetchUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userWithGroups: CognitoUserExt | undefined = {
          ...user,
          attributes: {
            ...user.attributes,
            groups:
              user?.signInUserSession.accessToken.payload["cognito:groups"],
            username: user?.signInUserSession.accessToken.payload.username,
          },
        };
        setUser(userWithGroups);
      } catch (error) {
        setUser(undefined);
      }
    }
    fetchUser();
  }, []);

  return user;
}

// // NOTE THIS IS A WORK IN PROGRESS - WOULD LIKE THE CACHE BUT ALSO NEED TO AWAIT RESPONSE TO MATCH ABOVE API
// export function useCurrentUser() {
//   // Returns the currently signed in user
//   const { data: user }: { data: CognitoUserExt | undefined } = useQuery({
//     queryKey: ["currentUser"],
//     queryFn: async () => {
//       const user = await Auth.currentAuthenticatedUser();
//       console.log(user);
//       return user;
//     },
//     onError: (error) => {
//       console.log("Error fetching current user", error);
//     },
//   });
//   return user;
// }
