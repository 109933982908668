import { Container, Typography } from "@mui/material";
import { ReactComponent as OPSMOLogoDarkBlue } from "../../images/OPSMO_logo_pack_svg/OPSMO_logo_dark_blue.svg";
import Box from "@mui/system/Box";
import { useMediaQuery, useTheme } from "@mui/material";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import greetingPageSvg from "../../images/greeting_page_abstract_shapes_wide.svg";

export const GreetingsPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useCurrentUser();
  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
        background: "linear-gradient(to top, #1DE9B6 50%, #01BFA5 50%)",
      }}
    >
      <Box
        style={{
          height: "100%",
          width: "100%",
          backgroundImage: `url(${greetingPageSvg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "100% auto",
        }}
      >
        <Container sx={{ height: "100%", paddingTop: 1 }}>
          <Box
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                variant="h2"
                sx={{
                  ...(isMobile ? { textAlign: "center" } : { marginLeft: 2 }),
                }}
                color="secondary"
              >
                {user
                  ? `Welcome, \n ${user?.attributes.given_name}!`
                  : `Welcome!`}
              </Typography>
            </Box>
            <Box
              sx={{
                ...(isMobile
                  ? {
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginBottom: 1,
                    }
                  : {
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: 1,
                    }),
              }}
            >
              {!isMobile && (
                <>
                  <Box
                    sx={{
                      width: 250,
                      marginLeft: 1,
                    }}
                  >
                    <OPSMOLogoDarkBlue />
                  </Box>
                  <Typography
                    color="secondary"
                    variant="h5"
                    sx={{ marginBottom: 1 }}
                  >
                    - Your bid writing support system.
                  </Typography>
                </>
              )}
              {isMobile && (
                <>
                  <Box
                    sx={{
                      width: 150,
                      marginLeft: 1,
                    }}
                  >
                    <OPSMOLogoDarkBlue />
                  </Box>
                  <Typography
                    color="secondary"
                    variant="h5"
                    sx={{ textAlign: "center", marginBottom: 1 }}
                  >
                    Your bid writing support system.
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
