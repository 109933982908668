/* eslint-disable */

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": process.env.REACT_APP_OPSMO_IDENTITY_POOL_ID,
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": process.env.REACT_APP_OPSMO_USER_POOL_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_OPSMO_APP_CLIENT_ID,
    "oauth": {},
    "aws_appsync_graphqlEndpoint": process.env.REACT_APP_OPSMO_APPSYNC_GRAPHQL_ENDPOINT,
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS", //"AWS_IAM"//
};

export default awsmobile;
