import { Amplify, Hub } from "@aws-amplify/core";
import createTheme, { ThemeOptions } from "@mui/material/styles/createTheme";
import { useEffect, useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { AuthPage } from "./components/UI/CustomAuthenticator";
import GilroyExtraBold from "./fonts/Gilroy/gilroy-extrabold-webfont.woff2";
import GilroyLight from "./fonts/Gilroy/gilroy-light-webfont.woff2";
import { Outlet } from "react-router-dom";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import awsExports from "./aws-exports";
import { MainAppBar } from "./components/UI/MainAppBar";
import { MainContent } from "./components/UI/MainContent";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useJiraIssues } from "./hooks/useJiraIssues";

const client = new QueryClient();

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#01BFA5", // primary green
      light: "#FFFFFF", //PRIM WHITE
      dark: "#EEEEEE", //PRIM GREY
      contrastText: "#212950", // dark blue  //primary blue "#0066ff"
    },
    secondary: {
      main: "#212950", // dark blue
      light: "#0066ff",
      dark: "#0066ff",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#f0f4c3",
      light: "#64FFDA", // secondary light light green
      dark: "#1DE9B6", // secondary light green
    },
    error: {
      main: "#FF3D00", //red
      light: "#0066ff",
      dark: "#0066ff",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#e4f0e2",
    },
  },

  typography: {
    fontFamily: [GilroyExtraBold, GilroyLight].join(","),
    fontWeightLight: 900,
    fontWeightRegular: 200,
    fontWeightMedium: 300,
    htmlFontSize: 14,
  },
};
const outerTheme = createTheme(themeOptions);

// Need to parse the Identity token not the Access to use the custom attributes
Amplify.configure({
  ...awsExports,
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  },
});

const initialFormState = {
  username: "",
  password: "",
  authCode: "",
  newPassword: "",
  confirmNewPassword: "",
  email: "",
  given_name: "",
  family_name: "",
  newPasswordForgot: "",
  confirmNewPasswordForgot: "",
  codeForgot: "",
  formType: "signIn",
};

export const App = () => {
  const [formState, updateFormState] = useState(initialFormState);
  const { formType } = formState;
  useJiraIssues();

  useEffect(() => {
    const checkUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          updateFormState((formState) => ({
            ...formState,
            formType: "signedIn",
          }));
        } else {
          throw new Error("User undefined");
        }
      } catch (err) {
        updateFormState((formState) => ({ ...formState, formType: "signIn" }));
      }
    };
    const setAuthListener = async () => {
      Hub.listen("auth", (data) => {
        switch (data.payload.event) {
          case "signOut":
            updateFormState((formState) => ({
              ...formState,
              formType: "signIn",
            }));
            break;
          case "signIn":
            break;
        }
      });
    };
    checkUser();
    setAuthListener();
  }, []);

  return (
    <QueryClientProvider client={client}>
      <ThemeProvider theme={outerTheme}>
        {formType !== "signedIn" && (
          <AuthPage
            formType={formType}
            formState={formState}
            updateFormState={updateFormState}
          />
        )}
        {formType === "signedIn" && (
          <>
            <MainAppBar />
            <MainContent>
              <Outlet />
            </MainContent>
          </>
        )}
      </ThemeProvider>
    </QueryClientProvider>
  );
};
