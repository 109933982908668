import { AccountCircle, Settings } from "@mui/icons-material";
import {
  Drawer,
  Box,
  List,
  // Badge,
  Divider,
} from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { StyledListItemButton } from "./StyledListItemButton";
// import NotificationsIcon from "@mui/icons-material/Notifications";
import AssignmentIcon from "@mui/icons-material/Assignment";
import IconButton from "@mui/material/IconButton";
import OPSMO_logo_green from "../../images/OPSMO_logo_pack_svg/OPSMO_logo_green.svg";
import OPSMO_icon_green from "../../images/OPSMO_logo_pack_svg/OPSMO_icon_green.svg";
import OPSMO_icon_white from "../../images/OPSMO_logo_pack_svg/OPSMO_icon_white.svg";

type Anchor = "top" | "left" | "bottom" | "right";

type LeftDrawerProps = {
  pathname: string;
};
export const LeftDrawer = ({ pathname }: LeftDrawerProps) => {
  const [drawState, setDrawState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawState({ ...drawState, [anchor]: open });
    };
  return (
    <Box>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={toggleDrawer("left", true)}
      >
        <img
          height={37}
          src={pathname === "/" ? OPSMO_icon_white : OPSMO_icon_green}
          alt="opsmo-icon"
        />
      </IconButton>
      <Drawer
        anchor={"left"}
        open={drawState["left"]}
        onClose={toggleDrawer("left", false)}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Box sx={{ marginTop: 1, marginLeft: 1 }}>
          <img height={50} src={OPSMO_logo_green} alt="opsmo-logo" />
        </Box>
        <Box
          sx={{
            width: 250,
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            padding: 1,
          }}
          role="presentation"
          onClick={toggleDrawer("left", false)}
          onKeyDown={toggleDrawer("left", false)}
        >
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            {/* <StyledListItemButton
              selected={false}
              label={"Notfications"}
              iconComponent={
                <Badge color="error" badgeContent={99}>
                  <NotificationsIcon />
                </Badge>
              }
            />
            <Divider /> */}
            {[
              {
                label: "Opportunities",
                to: "/opportunities",
                iconComponent: <AssignmentIcon />,
              },
              {
                label: "Submissions",
                to: "/submissions",
                iconComponent: <AccountCircle />,
              },
              {
                label: "Profiles",
                to: "/profiles",
                iconComponent: <AccountCircle />,
              },
            ].map((l, i) => (
              <StyledListItemButton
                key={`item-${i}`}
                selected={pathname === l.to}
                label={l.label}
                component={Link}
                to={l.to}
                iconComponent={l.iconComponent}
              />
            ))}
            <Box sx={{ flexGrow: 1 }} />
            <Divider />
            <StyledListItemButton
              selected={pathname === "/settings"}
              label={"Settings"}
              component={Link}
              to="/settings"
              iconComponent={<Settings />}
            />
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};
