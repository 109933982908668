import { ListItemButton, Box, ListItemText, ListItemIcon } from "@mui/material";

type StyledListItemButtonProps = {
  selected: boolean;
  divider?: boolean;
  label: string;
  onClick?: () => void;
  component?: any;
  to?: string;
  iconComponent?: JSX.Element;
};

export const StyledListItemButton = ({
  selected,
  divider,
  label,
  onClick,
  component,
  to,
  iconComponent,
}: StyledListItemButtonProps) => {
  const borderRadiusRight = "4px";
  const width = "6px";
  const height = "50%";
  return (
    <ListItemButton
      onClick={onClick}
      divider={divider}
      component={component}
      to={to}
      selected={selected}
      sx={{
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          top: "50%",
          left: 0,
          transform: "translateY(-50%)",
          height: height,
          width: selected ? width : 0,
          borderTopRightRadius: selected ? borderRadiusRight : 0,
          borderBottomRightRadius: selected ? borderRadiusRight : 0,
          // bgcolor: "primary.main",
          bgcolor: "grey",
          transition: "width 0.2s ease",
        },
        "&:hover::before": {
          width: width,
          borderTopRightRadius: borderRadiusRight,
          borderBottomRightRadius: borderRadiusRight,
        },
      }}
    >
      <Box
        sx={{
          borderTopRightRadius: borderRadiusRight,
          borderBottomRightRadius: borderRadiusRight,
          position: "absolute",
          transform: "translateY(-50%)",
          top: "50%",
          left: 0,
          height: height,
          width: selected ? width : 0,
          bgcolor: selected ? "primary.main" : "transparent",
          transition: "width 0.2s ease, background-color 0.2s ease",
        }}
      />
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        {iconComponent && <ListItemIcon>{iconComponent}</ListItemIcon>}
        <ListItemText
          primary={label}
          sx={{
            // bgcolor: selected ? "rgba(1, 191, 165, 0.08)" : "transparent",
            // borderRadius: borderRadiusRight,
            // marginLeft: -0.5,
            // paddingLeft: 0.5,
            // paddingRight: 1,
            // marginRight: -1,
            color: selected ? "black" : "grey",
          }}
        />
      </Box>
    </ListItemButton>
  );
};
