import { Auth } from "@aws-amplify/auth";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import { useState } from "react";
import registerPageSvg from "../../images/register_page_abstract_shapes.svg";
// import {ReactComponent as OPSMOIconDarkBlue} from '../../images/OPSMO_logo_pack_svg/OPSMO_icon_dark_blue.svg';
import { ReactComponent as OPSMOLogoDarkBlue } from "../../images/OPSMO_logo_pack_svg/OPSMO_logo_dark_blue.svg";
import { Stack, useMediaQuery, useTheme } from "@mui/material";

export const AuthPage = (props: {
  formType: any;
  formState: any;
  updateFormState: any;
}) => {
  const { formType, formState, updateFormState } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const onChange = (e: any) => {
    e.persist();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  };

  const handleSignUp = () => {
    const {
      username,
      email,
      password,
      confirmPassword,
      given_name,
      family_name,
    } = formState;
    if (password === confirmPassword) {
      Auth.signUp({
        username,
        password,
        attributes: { given_name, family_name, email },
        validationData: { username, password, given_name, family_name, email },
      })
        .then(() => {
          setShowErrorMsg(false);
          updateFormState(() => ({ ...formState, formType: "confirmSignUp" }));
        })
        .catch((error) => {
          setErrorMsg(error.message);
          setShowErrorMsg(true);
        });
    } else {
      setErrorMsg("Passwords do not match");
      setShowErrorMsg(true);
    }
  };

  const handleConfirmSignUp = () => {
    const { username, authCode } = formState;
    Auth.confirmSignUp(username, authCode)
      .then(() => {
        setShowErrorMsg(false);
        updateFormState(() => ({ ...formState, formType: "signIn" }));
      })
      .catch((error) => {
        setErrorMsg(error.message);
        setShowErrorMsg(true);
      });
  };

  const handleSignIn = () => {
    const { username, password } = formState;
    Auth.signIn(username, password)
      .then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setShowErrorMsg(false);
          updateFormState(() => ({
            ...formState,
            formType: "completeNewPassword",
          }));
        } else {
          setShowErrorMsg(false);
          updateFormState(() => ({ ...formState, formType: "signedIn" }));
        }
      })
      .catch((error) => {
        if (
          error.message ===
          "PreAuthentication failed with error Waitlist status is pending. Check back soon!."
        ) {
          setErrorMsg("There is currently a waitlist. Please check back soon!");
          setShowErrorMsg(true);
        } else {
          setErrorMsg(error.message);
          setShowErrorMsg(true);
        }
      });
  };

  const handleCompleteNewPassword = () => {
    const { username, password, newPassword, confirmNewPassword } = formState;
    if (newPassword === confirmNewPassword) {
      Auth.signIn(username, password)
        .then((user) => {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            Auth.completeNewPassword(user, newPassword)
              .then((user) => {
                setShowErrorMsg(false);
                updateFormState(() => ({ ...formState, formType: "signedIn" }));
              })
              .catch((error) => {
                setErrorMsg(error.message);
                setShowErrorMsg(true);
              });
          } else {
            // other situations
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setErrorMsg("Passwords do not match");
      setShowErrorMsg(true);
    }
  };

  const handleForgotPassword = () => {
    const { username } = formState;
    console.log(username);
    Auth.forgotPassword(username)
      .then((data) => {
        setShowErrorMsg(false);
        updateFormState(() => ({
          ...formState,
          formType: "completeForgotPassword",
        }));
      })
      .catch((error) => {
        setErrorMsg(error.message);
        setShowErrorMsg(true);
      });
  };

  const handleCompleteForgotPassword = () => {
    const {
      username,
      codeForgot,
      newPasswordForgot,
      confirmNewPasswordForgot,
    } = formState;
    if (newPasswordForgot === confirmNewPasswordForgot) {
      Auth.forgotPasswordSubmit(username, codeForgot, newPasswordForgot)
        .then((data) => {
          setShowErrorMsg(false);
          updateFormState(() => ({ ...formState, formType: "signedIn" }));
        })
        .catch((error) => {
          setErrorMsg(error.message);
          setShowErrorMsg(true);
        });
    } else {
      setErrorMsg("Passwords do not match");
      setShowErrorMsg(true);
    }
  };

  return (
    <div
      className="sign-up-background"
      style={{
        backgroundImage: `url(${registerPageSvg})`,
        ...(!isMobile ? { height: "100%" } : {}),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        overflowY: "scroll",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          p: 6,
        }}
      >
        <Box sx={{ pl: 6, pr: 6, pb: 2 }}>
          {formType === "signUp" && (
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h3" color="secondary" gutterBottom>
                Thanks for your interest!
              </Typography>
              <Typography variant="h4" color="secondary" gutterBottom>
                Sign up to the waitlist, verify your email, and we'll be in
                touch soon when access is available
              </Typography>
            </Box>
          )}
          {formType === "confirmSignUp" && (
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h3" color="secondary" gutterBottom>
                Check your emails
              </Typography>
              <Typography variant="h4" color="secondary" gutterBottom>
                Enter the code to complete signup
              </Typography>
            </Box>
          )}
          {formType === "signIn" && (
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h3" color="secondary" gutterBottom>
                Write winning proposals
              </Typography>
              <Box>
                <Typography variant="h4" color="secondary" gutterBottom>
                  AI-assisted submission writing
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Card
              variant="outlined"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box>
                <Box
                  sx={{
                    mt: 1,
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {/* <OPSMOIconDarkBlue style={{maxHeight: 105, paddingRight: 10}}/> */}
                  <OPSMOLogoDarkBlue />
                </Box>

                <Stack
                  spacing={2}
                  component="form"
                  sx={{
                    "& > :not(style)": { width: "25ch" },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    p: 2,
                  }}
                >
                  {formType === "signUp" && (
                    <>
                      <TextField
                        label="Username"
                        variant="filled"
                        name="username"
                        onChange={onChange}
                        placeholder="Enter your username"
                      />
                      <TextField
                        label="Email"
                        variant="filled"
                        name="email"
                        onChange={onChange}
                        placeholder="Enter your email"
                      />
                      <TextField
                        label="Password"
                        variant="filled"
                        name="password"
                        type="password"
                        onChange={onChange}
                        placeholder="password"
                      />
                      <TextField
                        label="Confirm Password"
                        variant="filled"
                        name="confirmPassword"
                        type="password"
                        onChange={onChange}
                        placeholder="confirm password"
                      />
                      <TextField
                        label="Given Name"
                        variant="filled"
                        name="given_name"
                        onChange={onChange}
                        placeholder="Enter your given name"
                      />
                      <TextField
                        label="Family Name"
                        variant="filled"
                        name="family_name"
                        onChange={onChange}
                        placeholder="Enter your family name"
                      />
                      {showErrorMsg && (
                        <Alert
                          severity="error"
                          onClose={() => setShowErrorMsg(false)}
                        >
                          {errorMsg}
                        </Alert>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSignUp}
                        fullWidth
                      >
                        Sign Up
                      </Button>
                      <Typography variant="body1">
                        Already signed up?
                      </Typography>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          setShowErrorMsg(false);
                          updateFormState(() => ({
                            ...formState,
                            formType: "signIn",
                          }));
                        }}
                        fullWidth
                      >
                        Sign In instead
                      </Button>
                      <Box sx={{ mb: 1 }}></Box>
                    </>
                  )}

                  {formType === "confirmSignUp" && (
                    <>
                      <Typography>
                        Check your emails for the authorisation code
                      </Typography>
                      <TextField
                        label="Auth Code"
                        variant="filled"
                        name="authCode"
                        onChange={onChange}
                        placeholder="confirm auth code"
                      />
                      {showErrorMsg && (
                        <Alert
                          severity="error"
                          onClose={() => setShowErrorMsg(false)}
                        >
                          {errorMsg}
                        </Alert>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirmSignUp}
                        fullWidth
                      >
                        Confirm Sign up
                      </Button>
                      <Box sx={{ mb: 1 }}></Box>
                    </>
                  )}

                  {formType === "signIn" && (
                    <>
                      <TextField
                        label="Username"
                        variant="filled"
                        name="username"
                        onChange={onChange}
                        placeholder="Username"
                      />
                      <TextField
                        label="Password"
                        variant="filled"
                        name="password"
                        type="password"
                        onChange={onChange}
                        placeholder="Password"
                      />
                      {showErrorMsg && (
                        <Alert
                          severity="error"
                          onClose={() => setShowErrorMsg(false)}
                        >
                          {errorMsg}
                        </Alert>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSignIn}
                        fullWidth
                      >
                        Sign In
                      </Button>
                      <Typography variant="body1">No account yet?</Typography>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          setShowErrorMsg(false);
                          updateFormState(() => ({
                            ...formState,
                            formType: "signUp",
                          }));
                        }}
                        fullWidth
                      >
                        Sign Up now
                      </Button>
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={() => {
                          setShowErrorMsg(false);
                          updateFormState(() => ({
                            ...formState,
                            formType: "forgotPassword",
                          }));
                        }}
                        fullWidth
                      >
                        Forgot Password
                      </Button>
                    </>
                  )}

                  {formType === "completeNewPassword" && (
                    <>
                      <TextField
                        label="New Password"
                        variant="filled"
                        name="newPassword"
                        type="password"
                        onChange={onChange}
                        placeholder="New Password"
                      />
                      <TextField
                        label="Confirm New Password"
                        variant="filled"
                        name="confirmNewPassword"
                        type="password"
                        onChange={onChange}
                        placeholder="Confirm New Password"
                      />
                      {showErrorMsg && (
                        <Alert
                          severity="error"
                          onClose={() => setShowErrorMsg(false)}
                        >
                          {errorMsg}
                        </Alert>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCompleteNewPassword}
                        fullWidth
                      >
                        Change Password
                      </Button>
                    </>
                  )}

                  {formType === "forgotPassword" && (
                    <>
                      <Typography variant="body1">
                        Enter your username to reset the password. A code will
                        be sent to your email.
                      </Typography>
                      <TextField
                        label="Username"
                        variant="filled"
                        name="username"
                        onChange={onChange}
                        placeholder="Enter Username"
                      />
                      {showErrorMsg && (
                        <Alert
                          severity="error"
                          onClose={() => setShowErrorMsg(false)}
                        >
                          {errorMsg}
                        </Alert>
                      )}
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleForgotPassword}
                        fullWidth
                      >
                        Reset Password
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          updateFormState(() => ({
                            ...formState,
                            formType: "signIn",
                          }))
                        }
                        fullWidth
                      >
                        Cancel
                      </Button>
                      <Box sx={{ mb: 1 }}></Box>
                    </>
                  )}

                  {formType === "completeForgotPassword" && (
                    <>
                      <TextField
                        label="Code"
                        variant="filled"
                        name="codeForgot"
                        onChange={onChange}
                        placeholder="Enter code"
                      />
                      <TextField
                        label="New Password"
                        variant="filled"
                        name="newPasswordForgot"
                        type="password"
                        onChange={onChange}
                        placeholder="new password"
                      />
                      <TextField
                        label="Confirm New Password"
                        variant="filled"
                        name="confirmNewPasswordForgot"
                        type="password"
                        onChange={onChange}
                        placeholder="confirm new password"
                      />
                      {showErrorMsg && (
                        <Alert
                          severity="error"
                          onClose={() => setShowErrorMsg(false)}
                        >
                          {errorMsg}
                        </Alert>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCompleteForgotPassword}
                        fullWidth
                      >
                        Change Password
                      </Button>
                    </>
                  )}
                </Stack>
              </Box>
            </Card>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
