import { createTheme, ThemeProvider } from "@mui/material/styles";
import { themeOptions } from "../../App";
import SkeletonLoad from "./SkeletonLoad";
import { Container } from "@mui/material";
import { MainAppBar } from "./MainAppBar";

const outerTheme = createTheme(themeOptions);

export default function Fallback() {
  return (
    <ThemeProvider theme={outerTheme}>
      <MainAppBar />
      <Container sx={{ mt: 2, mb: 0 }}>
        <SkeletonLoad />
      </Container>
    </ThemeProvider>
  );
}
