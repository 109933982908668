import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import default_profile from "../../images/default_profile.svg";
import { Avatar, IconButton } from "@mui/material";

type AvatarMenuOptions = {
  onClick: (() => Promise<any>) | (() => void);
  label: string;
};
type AvatarMenuProps = {
  avatarMenuOptions: AvatarMenuOptions[];
};
export default function AvatarMenu({ avatarMenuOptions }: AvatarMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        color="primary"
        aria-label="avatar-menu-icon"
        onClick={handleClick}
        aria-controls={open ? "avatar-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Avatar alt="Default profile" src={default_profile} sx={{ p: 0 }} />
      </IconButton>
      <Menu
        id="avatar-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "avatar-menu-icon",
        }}
      >
        {avatarMenuOptions.map((option, i) => (
          <MenuItem key={`avatar-menu-option-${i}`} onClick={option.onClick}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
