import { Auth } from "@aws-amplify/auth";
import { AppBar, Toolbar, Box, Breadcrumbs, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AvatarMenu from "./AvatarMenu";
import { LeftDrawer } from "./LeftDrawer";
import { Link as MuiLink } from "@mui/material";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useMediaQuery, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";

export const MainAppBar = () => {
  let location = useLocation();
  let pathname = location?.pathname;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useCurrentUser();
  const signOut = () => Auth.signOut();
  const pathParts = pathname?.split("/");
  const breadCrumbLinks = pathParts.slice(1).map((p, i) => {
    let breadCrumbLink;
    if (i !== pathParts.slice(1).length - 1) {
      breadCrumbLink = (
        <MuiLink
          key={i}
          component={Link}
          underline="hover"
          color="inherit"
          to={pathParts.slice(1, i + 2).join("/")}
        >
          {p}
        </MuiLink>
      );
    } else {
      breadCrumbLink = (
        <Typography key={i} color="text.primary">
          {p}
        </Typography>
      );
    }
    return breadCrumbLink;
  });

  const navigate = useNavigate();
  const dropdownNavOptions = pathParts
    .slice(0, -1)
    .filter((pathPart) => pathPart !== "")
    .map((pathPart) => ({
      onClick: () => navigate(pathPart),
      label: pathPart.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()),
    }));
  if (pathParts.filter((pathPart) => pathPart !== "").length) {
    dropdownNavOptions.push({ onClick: () => navigate("/"), label: "Home" });
  }
  dropdownNavOptions.push({ onClick: signOut, label: "Sign Out" });

  return (
    <ErrorBoundary>
      <AppBar
        style={{
          backgroundColor: pathname === "/" ? "#01BFA5" : "#EEEEEE",
        }}
        position={"sticky"}
        elevation={0}
      >
        <Toolbar>
          <LeftDrawer pathname={pathname} />
          <Box sx={{ flexGrow: 1, minWidth: "80px", overflowX: "auto" }}>
            {!isMobile && (
              <Breadcrumbs
                aria-label="breadcrumb"
                maxItems={3}
                sx={{
                  whiteSpace: "nowrap",
                  display: "inline-block",
                }}
              >
                <MuiLink
                  component={Link}
                  underline="hover"
                  color="inherit"
                  to="/"
                >
                  Home
                </MuiLink>
                {breadCrumbLinks}
              </Breadcrumbs>
            )}
          </Box>
          {!isMobile && (
            <Typography
              variant="h6"
              component="div"
              sx={{ marginRight: 1 }}
              color="secondary"
            >
              {user?.attributes.given_name} {user?.attributes.family_name}
            </Typography>
          )}
          <AvatarMenu
            avatarMenuOptions={
              isMobile
                ? dropdownNavOptions
                : [{ onClick: signOut, label: "Sign Out" }]
            }
          />
        </Toolbar>
      </AppBar>
    </ErrorBoundary>
  );
};
