import "./index.css";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import React, { Suspense, lazy } from "react";
import { App } from "./App";
import { Container, Typography } from "@mui/material";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import Fallback from "./components/UI/Fallback";
import { GreetingsPage } from "./components/UI/GreetingsPage";

const Opportunities = lazy(() =>
  import("./components/Opportunities/Opportunities").then((module) => ({
    default: module.Opportunities,
  }))
);
const OpportunitiesTable = lazy(() =>
  import("./components/Opportunities/OpportunitiesTable").then((module) => ({
    default: module.OpportunitiesTable,
  }))
);
const OpportunityCard = lazy(() =>
  import("./components/Opportunities/OpportunityCard").then((module) => ({
    default: module.OpportunityCard,
  }))
);
const SubmissionsTable = lazy(() =>
  import("./components/Submissions/SubmissionsTable").then((module) => ({
    default: module.SubmissionsTable,
  }))
);
const Settings = lazy(() =>
  import("./components/Settings/Settings").then((module) => ({
    default: module.Settings,
  }))
);
const ProfilesTable = lazy(() =>
  import("./components/Profile/ProfilesTable").then((module) => ({
    default: module.ProfilesTable,
  }))
);
const ProfileCard = lazy(() =>
  import("./components/Profile/ProfileCard").then((module) => ({
    default: module.ProfileCard,
  }))
);
const AuthCtrl = lazy(() =>
  import("./components/Authorisation/AuthCtrl").then((module) => ({
    default: module.AuthCtrl,
  }))
);
const BodyQuestions = lazy(() =>
  import("./components/Body/BodyQuestions").then((module) => ({
    default: module.BodyQuestions,
  }))
);
const ProjectPlan = lazy(() =>
  import("./components/ProjectPlan/ProjectPlan").then((module) => ({
    default: module.ProjectPlan,
  }))
);
const Review = lazy(() =>
  import("./components/Review/Review").then((module) => ({
    default: module.Review,
  }))
);
const RiskAssessment = lazy(() =>
  import("./components/RiskAssessment/RiskAssessment").then((module) => ({
    default: module.RiskAssessment,
  }))
);
const SubmissionSetupComp = lazy(() =>
  import("./components/Submissions/SubmissionSetupComp").then((module) => ({
    default: module.SubmissionSetupComp,
  }))
);
const Team = lazy(() =>
  import("./components/Team/Team").then((module) => ({
    default: module.Team,
  }))
);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={<Fallback />}>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="" element={<GreetingsPage />} />
            <Route path="opportunities" element={<Opportunities />}>
              <Route index element={<OpportunitiesTable />} />
              <Route path=":id" element={<OpportunityCard />} />
            </Route>
            {/* <Route path="tenders_analytics" element={<OpportunitiesAnalytics />} /> */}
            <Route path="submissions" element={<Outlet />}>
              <Route index element={<SubmissionsTable />} />
              <Route path=":id/*" element={<Outlet />}>
                <Route index element={<Navigate to="Setup" replace />} />
                <Route path="Setup" element={<SubmissionSetupComp />}></Route>
                <Route path="Team" element={<Team />}></Route>
                <Route path="Auth" element={<AuthCtrl />}></Route>
                <Route path="Plan" element={<ProjectPlan />}></Route>
                <Route path="Risks" element={<RiskAssessment />}></Route>
                <Route path="Body" element={<BodyQuestions />}></Route>
                <Route path="Review" element={<Review />}></Route>
              </Route>
            </Route>
            <Route path="profiles" element={<Outlet />}>
              <Route index element={<ProfilesTable />} />
              <Route path=":id" element={<ProfileCard />} />
            </Route>
            <Route path="settings" element={<Settings />} />
            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <Container sx={{ height: "100%", paddingTop: 1 }}>
                    <Typography>There's nothing here!</Typography>
                  </Container>
                </main>
              }
            />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
